import { Grid } from '@mui/material'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import { StyledNameField } from '../components/reusableComponents'
import {
	getApplicableRules,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import {
	OwnerSelect,
	DropList,
	CountryListSelector,
} from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { SubmitButton } from '../components/Buttons'
import { useState } from 'react'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { Owner } from './AdvertiserPopUp'

//this is the main component for editing an advertiser. Here is the logic that handles every input.

interface CompanyInfoProps {
	name?: string | null
	setName?: (value: string) => void
	legalName?: string
	setLegalName?: (value: string) => void
	address?: string
	setAddress?: (value: string) => void
	geo?: string[]
	setGeo?: (value: string[]) => void
	linkToIO?: string
	setLinkToIO?: (value: string) => void
	setOwnerEmail?: (value: string) => void
	ownerEmail?: string | null
	setSalesRep?: (value: string) => void
	salesRep?: string
	setStatus?: (value: boolean) => void
	status?: boolean
	setApps?: (value: string[]) => void
	apps?: string[]
	setPlatforms?: (value: string[] | null) => void
	platforms?: string[] | null
	setFraudTool?: (value: string[]) => void
	fraudTool?: string[]
	linkToAdvFile?: string
	setLinkToAdvFile?: (value: string) => void
	linkToCreative?: string
	setLinkToCreative?: (value: string) => void
	team?: string
	setTeam?: (value: string) => void
	communicationChannel?: string[]
	setCommunicationChannel?: (value: string[]) => void
	advertiserType?: string
	setAdvertiserType?: (value: string) => void
	vertical?: string[]
	setVertical?: (value: string[]) => void
	isValid?: boolean
	settings?: any
	loginRole?: string
	createdBy?: string
	loginUser?: string
	appList?: string[]
	setMainHeightChange?: (value: any) => void
	pauseReason?: string
	setPauseReason?: (value: string) => void
	pauseExplanation?: string
	setPauseExplanation?: (value: string) => void
	ViewPauseBox?: boolean
	setViewPauseBox?: (value: boolean) => void
	setStatusChanged?: (value: boolean) => void
	website?: string
	setWebsite?: (value: string) => void
	linkedin?: string
	setLinkedin?: (value: string) => void
	brief?: string
	setBrief?: (value: string) => void
	specialRequest?: string
	setSpecialRequest?: (value: string) => void
	pastOwner?: Owner[]
	setPastOwner?: (value: Owner[]) => void
	pastOwnerFinance?: any
	setPastOwnerFinance?: (value: any) => void
	parametersTemplate?: string
	setParametersTemplate?: (value: string) => void
	users?: any
	mandatoryFields?: any
	fieldValidations?: any
	permissions: any
	handlePastOwner?: any
	handlePastOwnerFinance?: any
}

export const CompanyInfoComponent = (props: CompanyInfoProps) => {
	const { settings, login } = useTypedSelector((state) => state)
	const [nameTouched, setNameTouched] = useState(false)
	const permissions = props.permissions

	const validateDirectType = (value: string) => {
		return false
	}

	return (
		<Grid
			container
			columns={12}
			columnSpacing={6}
			rowSpacing={4}
			direction='row'
			style={{ width: '98%' }}
		>
			{props.ViewPauseBox && props.status !== undefined && (
				<>
					<div
						style={{
							background: 'rgba(0,0,0,0.2)',
							width: '120vw',
							height: '200vh',
							display: 'flex',
							top: '-10%',
							left: '-10%',
							position: 'absolute',
							zIndex: '1',
						}}
					></div>
					<div
						style={{
							background: 'white',
							position: 'absolute',
							display: 'flex',
							top: '40%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							border: '1px solid black',
							borderRadius: '30px',
							flexDirection: 'column',
							zIndex: '10',
							height: '24%',
							width: '32%',
							padding: '2rem 4rem 2rem 4rem',
						}}
					>
						<p style={{ color: 'black', width: '110%', marginLeft: '-12px' }}>
							Please, note that disabling the advertiser will also disable all
							the campaigns connected to it.
						</p>
						<SubmitButton
							disabled={!permissions.edit}
							style={{ margin: 'auto' }}
							onClick={() => {
								props.setViewPauseBox?.(false)
							}}
						>
							Close
						</SubmitButton>
					</div>
				</>
			)}

			<Grid item xs={3}>
				<InputFieldText
					label='Company Name'
					placeholder='Company Name'
					type='text'
					value={props.name || ''}
					onChange={(e: string) => {
						setNameTouched(true)
						props.setName?.(e)
					}}
					disabled={!permissions.edit}
					errormessage={
						nameTouched && !stringIsValidName(props.name || '')
							? "Company name is mandatory. Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Legal Name'
					placeholder='Legal Name'
					type='text'
					value={props.legalName || ''}
					onChange={(e: string) => props.setLegalName?.(e)}
					disabled={!permissions.edit}
					mandatory={!props.fieldValidations.legalName}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Company Address'
					type='text'
					value={props.address || ''}
					onChange={(e: string) => props.setAddress?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={3}>
				<CountryListSelector
					label='Head Office Geo'
					options={countryList}
					value={props.geo || []}
					singular
					onChange={(e: any) => props.setGeo?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			{props.ownerEmail && (
				<Grid item xs={3}>
					<OwnerSelect
						onChange={(e: any) => {
							props.handlePastOwner(e)
							props.handlePastOwnerFinance(e)
							props.setOwnerEmail?.(e)
						}}
						label={'Owner'}
						value={props.ownerEmail}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setPlatforms?.(e)}
					options={props.settings?.settings?.platforms?.sort() || []}
					label={'Platforms'}
					errormessage={
						validateDirectType(props.advertiserType || '')
							? 'Direct-type advertisers must have only one platform'
							: undefined
					}
					openWithClick
					value={props.platforms || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>
			{props.salesRep && (
				<Grid item xs={3}>
					<DropList
						onChange={(e: any) => props.setSalesRep?.(e)}
						options={props.settings?.settings?.salesRepresentative || []}
						label={'Sales Representative'}
						value={props.salesRep || ''}
						disabled={!permissions.edit}
						noNull={true}
					/>
				</Grid>
			)}

			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setCommunicationChannel?.(e)}
					options={props.settings?.settings?.communicationChannel?.sort() || []}
					label={'Communication channel'}
					openWithClick
					value={props.communicationChannel || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => {
						props.setAdvertiserType?.(e)
					}}
					options={['Direct', 'Network', 'Agency']}
					label={'Type'}
					value={props.advertiserType || ''}
					disabled={!permissions.edit}
					noNull={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='LinkedIn'
					type='text'
					value={props.linkedin || ''}
					onChange={(e: string) => props.setLinkedin?.(e)}
					color={
						props.linkedin &&
						!checkInputStartsWithHttp(props.linkedin) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.linkedin
							? !checkInputStartsWithHttp(props.linkedin)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setFraudTool?.(e)}
					options={props.settings?.settings?.fraudTools?.sort() || []}
					label={'Fraud Tool'}
					openWithClick
					value={props.fraudTool || []}
					disabled={!permissions.edit || props.advertiserType === 'Network'}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Google Drive'
					type='text'
					value={props.linkToIO || ''}
					onChange={(e: string) => props.setLinkToIO?.(e)}
					color={
						props.linkToIO &&
						!checkInputStartsWithHttp(props.linkToIO) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.linkToIO
							? !checkInputStartsWithHttp(props.linkToIO)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Website'
					type='text'
					value={props.website || ''}
					onChange={(e: string) => props.setWebsite?.(e)}
					color={
						props.website &&
						!checkInputStartsWithHttp(props.website) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.website
							? !checkInputStartsWithHttp(props.website)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			{props.ownerEmail && (
				<Grid item xs={3}>
					<DropList
						onChange={(e: any) => {
							props.setStatus?.(e === 'Active')
							props.setStatusChanged?.(true)
						}}
						options={['Active', 'Disabled']}
						label={'Status'}
						value={props.status === true ? 'Active' : 'Disabled'}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => props.setVertical?.(e)}
					options={props.settings?.settings?.vertical?.sort() || []}
					label={'Vertical'}
					openWithClick
					value={props.vertical || []}
					disabled={!permissions.edit}
					onlyOptionsAllowed
					errored={
						props.advertiserType === 'Direct' &&
						props.vertical &&
						props.vertical.length === 0
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Creative'
					type='text'
					value={props.linkToCreative || ''}
					onChange={(e: string) => props.setLinkToCreative?.(e)}
					color={
						props.linkToCreative &&
						!checkInputStartsWithHttp(props.linkToCreative) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!permissions.edit}
					errormessage={
						props.linkToCreative
							? !checkInputStartsWithHttp(props.linkToCreative)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			{props.status === false && (
				<>
					<Grid item xs={3}>
						<DropList
							onChange={(e: any) => props.setPauseReason?.(e)}
							options={props.settings?.settings?.pauseReason?.sort() || []}
							label={'Pause Reason'}
							value={props.pauseReason || ''}
							multiple={false}
							disabled={!permissions.edit}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Explanation'
							type='text'
							value={props.pauseExplanation || ''}
							onChange={(e: string) => props.setPauseExplanation?.(e)}
							disabled={!permissions.edit}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				<ChipInputList
					onChange={(e: any) => props.setApps?.(e)}
					label={'App Ids'}
					options={props.appList || []}
					value={props.apps || []}
					hint='iOS: The format for the app id should be idxxxxxxxxx &nbsp;
                        Android: The format for the app id should be com.xxxx.xxxx'
					disabled={!permissions.edit || props.advertiserType === 'Network'}
					mandatory={
						props.advertiserType === 'Direct' && props.apps?.length === 0
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<StyledTextAreaField
					label='Brief'
					value={props.brief || ''}
					onChange={(e: string) => props.setBrief?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={4}>
				<StyledTextAreaField
					label='Special Request'
					value={props.specialRequest || ''}
					onChange={(e: string) => props.setSpecialRequest?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={4}>
				<StyledTextAreaField
					label='Parameters Template'
					value={props.parametersTemplate || ''}
					onChange={(e: string) => props.setParametersTemplate?.(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
		</Grid>
	)
}
