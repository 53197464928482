import React, { useEffect, useState } from 'react'
import { CountryListSelector } from './SelectableInputs'
import { ChipInputList } from './ChipInput'
import { useTheme } from '@mui/material'
import { CSSProperties } from 'react'

const styles = {
	checkedFilterContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '260px',
		position: 'relative' as CSSProperties['position'],
	},
	placeholderText: (theme: any): CSSProperties => ({
		position: 'absolute',
		left: '20%',
		top: '50%',
		color: theme.colors.text.grey,
		pointerEvents: 'none',
		padding: '0 19px',
	}),
}

const CheckedFilter = (props: {
	id: any
	onChange: (checked: boolean) => void
	checked: boolean
	label: string
	arrayOnChange: any
	array: any[]
	value?: any[]
	disabled?: boolean
	setDataObj?: any
	dataObj?: any
	placeholder?: string
	children?: React.ReactNode
}) => {
	const [extraFilter, setExtraFilter] = useState(false)
	const theme = useTheme()

	// Check if the field requires special handling
	useEffect(() => {
		const possibleFilters = [
			'app_id',
			'media_source_pid',
			'campaign_name',
			'campaign_id',
			'country',
			'agencyAccounts',
		]
		if (possibleFilters.includes(props.id)) {
			setExtraFilter(true)
		}
	}, [props.id])

	// Render PID filter without lazy loading
	if (props.id === 'media_source_pid') {
		return (
			<div style={styles.checkedFilterContainer}>
				{props.checked ? (
					<div key={props.id} style={{ display: 'flex', width: '100%' }}>
						{props.children ? (
							props.children
						) : (
							<div style={{ width: '100%', position: 'relative' }}>
								{(!props.value || props.value.length === 0) && (
									<span style={styles.placeholderText(theme)}>
										{props.placeholder}
									</span>
								)}
								<div style={{ maxHeight: '200px' }}>
									<ChipInputList
										{...props}
										onChange={props.arrayOnChange}
										options={props.array} // Pass full PID list
										value={props.value || ['']}
										openWithClick={true}
										style={{ width: '100%' }}
										minCharsBeforeSearch={3} // Require at least 3 characters
									/>
								</div>
							</div>
						)}
					</div>
				) : null}
			</div>
		)
	}

	// Default behavior for other fields
	return (
		<div style={styles.checkedFilterContainer}>
			{props.checked ? (
				props.label !== 'Country' ? (
					<div key={props.id} style={{ display: 'flex', width: '100%' }}>
						{props.children ? (
							props.children
						) : (
							<div style={{ width: '100%', position: 'relative' }}>
								{(!props.value || props.value.length === 0) && (
									<span style={styles.placeholderText(theme)}>
										{props.placeholder}
									</span>
								)}
								<ChipInputList
									{...props}
									onChange={props.arrayOnChange}
									options={props.array}
									value={props.value || ['']}
									openWithClick={true}
									style={{ width: '100%' }}
								/>
							</div>
						)}
					</div>
				) : (
					<div key={props.id} style={{ display: 'flex', width: '100%' }}>
						{(!props.value || props.value.length === 0) && (
							<span style={styles.placeholderText(theme)}>
								{props.placeholder}
							</span>
						)}
						<CountryListSelector
							{...props}
							onChange={props.arrayOnChange}
							options={props.array}
							value={props.value || ['']}
							style={{ width: '100%' }}
						/>
					</div>
				)
			) : null}
		</div>
	)
}

export default CheckedFilter
