import ClearIcon from '@mui/icons-material/Clear'
import { MenuItem, Paper, Popper, debounce, useTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorTag, InfoTag, StyledLabel } from './Informative'
import {
	InputFieldText,
	RadioChip,
	StyledAutocomplete,
	StyledTextInputField,
} from './Inputs'

import './style.css'

export const ChipInputList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	disabled?: boolean
	value?: string[]
	float?: 'left' | 'right'
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
	clickableFunction?: (option: any, ev: React.MouseEvent) => void
	errored?: boolean
	errormessage?: any
	inputStyle?: any
	anchorId?: string
	chipStyle?: React.CSSProperties
	noColoredBorder?: boolean
	mandatory?: boolean
	minCharsBeforeSearch?: number
}) => {
	const theme = useTheme()
	const ref = useRef()
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<any>('')
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)
	const [interacted, setInteracted] = useState(false)
	const [inputValue, setInputValue] = useState('')

	useEffect(() => {
		if (props.anchorId) {
			setAnchorEl(document.getElementById(props.anchorId))
		}
	}, [])

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]): any => {
		if (list.indexOf(value) !== -1) {
			return {
				color: theme.colors.base.green300,
				width: '100%',
				position: 'relative',
			}
		}
	}

	// const errorStyling = () => {
	// 	if (props.errormessage) {
	// 		return {
	// 			boxShadow: 'red 0px 0px 7px',
	// 		}
	// 	}
	// }
	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (props.disabled) {
			style.background = theme.colors.base.grey50
			return style
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value && props.value.length === 0 && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value && props.value.length > 0 && !props.errormessage) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.errormessage) {
			style.borderRight = '6px solid ' + theme.colors.base.red300
		}
		if (props.noColoredBorder) {
			style.borderRight = '1px solid ' + theme.colors.base.grey300
		}
		return style
	}

	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={{
						fontSize: theme.font.size.body,
						...StyleForSelectedOption(option, props.value ? props.value : []),
					}}
				>
					{option}
				</MenuItem>
			</div>
		)
	}

	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((option: any, index: number) => {
			const CustomLabel = (props: any) => {
				const { children } = props
				return (
					<div
						style={{
							whiteSpace: 'normal',
							overflow: 'auto',
							textOverflow: 'ellipsis',
							paddingLeft: '2px',
							paddingRight: '2px',
						}}
					>
						{children}
					</div>
				)
			}
			return (
				<Chip
					variant='outlined'
					onClick={(ev: React.MouseEvent) => {
						if (props.clickableFunction) {
							props.clickableFunction(option, ev)
						}
					}}
					label={
						props.ShowSelected ? <CustomLabel>{option}</CustomLabel> : option
					}
					deleteIcon={
						<ClearIcon
							style={{ scale: '0.8', color: theme.colors.text.titles }}
						/>
					}
					style={{
						width: 'auto',
						minWidth: props.ShowSelected ? '100px' : '0px',
						fontSize: theme.font.size.caption,
						height: 'auto',
						color: theme.colors.text.titles,
						...props.chipStyle,
					}}
					{...getTagProps({ index })}
					key={index + '-chip'}
				/>
			)
		})

		return chips
	}

	const CustomPopper = function (props: any) {
		return (
			<Popper
				{...props}
				disablePortal
				anchorEl={anchorEl ? anchorEl : props.anchorEl}
				style={{ width: 'auto', background: theme.colors.base.white }}
				placement='bottom-start'
			/>
		)
	}
	const renderInputField = (params: any) => (
		<>
			<div
				style={{
					display: 'flex',
					position: 'relative',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<StyledLabel focused={focused}>
					{props.label && (
						<label>
							{props.label}
							{props.mandatory &&
								(!props.value || props.value.length === 0) && (
									<span
										style={{
											color: 'red',
											fontSize: '0.8rem',
											fontWeight: 'bold',
										}}
									>
										*
									</span>
								)}
						</label>
					)}
					{props.hint ? (
						<span
							style={{
								marginLeft: '8px',
								position: 'relative',
								display: 'inline-flex',
								top: '0px',
							}}
						>
							<InfoTag
								title={props.hint}
								style={{
									top: '0px',
									position: 'relative',
									color: theme.colors.base.grey600,
									display: 'flex',
									borderRadius: '50%',
									alignItems: 'center',
									textAlign: 'center',
									justifyContent: 'center',
									padding: '0px',
									fontSize: '10px',
									lineHeight: '16px',
									border: '1px solid ' + theme.colors.base.grey600,
									backgroundColor: theme.colors.base.white,
									boxShadow: 'none',
								}}
							/>
						</span>
					) : null}
				</StyledLabel>
			</div>
			<div
				style={{ width: '100%', position: 'relative' }}
				className={'chipinput-display'}
			>
				<StyledTextInputField
					{...params}
					focused
					label={undefined}
					margin='dense'
					key={params + 'key-input'}
					variant='outlined'
					style={{
						float: props.float,
						margin: '0px',
						// backgroundColor: props.errored ? '#eeafaf' : 'rgba(238,238,238,0.6)',
						// boxShadow: props.errored ? 'red 0px 0px 7px' : 'none',
						...responsiveStyle(),
						...props.inputStyle,
					}}
					onMouseEnter={() => {
						setHovered(true)
					}}
					onMouseLeave={() => {
						setHovered(false)
					}}
					onFocus={() => {
						setFocused(true)
						setInteracted(true)
					}}
					onBlur={() => {
						props.onBlur && props.onBlur()
						setFocused(false)
					}}
					InputProps={{
						...params.InputProps,
						style: {
							...props.inputStyle,
						},
					}}
					// InputLabelProps={{
					// 	style: { top: '-10px' },
					// }}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
					placeholder={
						props.minCharsBeforeSearch &&
						inputValue.length < props.minCharsBeforeSearch
							? `Type at least ${props.minCharsBeforeSearch} characters`
							: props.placeholder
					}
				/>
				{props.errormessage ? (
					<div
						style={{
							position: 'absolute',
							right: '10%',
							top: '34%',
							border: 'none',
							cursor: 'pointer',
							zIndex: '22',
						}}
					>
						<ErrorTag title={props.errormessage} />
					</div>
				) : null}
			</div>
		</>
	)
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll &&
				props.value &&
				props.options &&
				props.value.length === props.options.length
					? false
					: open
			}
			options={orderedOptions}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			filterOptions={(options: any, state: any) => {
				const { inputValue } = state
				const minChars = props.minCharsBeforeSearch || 0

				if (inputValue.length < minChars) {
					return []
				}

				return options.filter((option: any) =>
					option.toLowerCase().includes(inputValue.toLowerCase()),
				)
			}}
			style={{ ...props.style }}
			id={'tags-filled'}
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value.length === (props.options?.length || 0)
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			onChange={(a: any, n: any) => {
				if (props.onlyOneOptionAllowed) {
					n.reverse()
					n[0] ? props.onChange([n[0]]) : props.onChange([])
				}
				if (props.onlyOptionsAllowed) {
					if (compareArrays(n, props.options as string[])) {
						props.onChange(n)
					} else return null
				} else props.onChange(n)
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
			PopperComponent={CustomPopper}
			PaperComponent={({ children, ...paperProps }) => (
				<Paper
					{...paperProps}
					sx={{
						border: `1px solid ${theme.colors.base.grey300}`,
						padding: 1,
						color: theme.colors.text.titles,
						background: theme.colors.base.white,
					}}
				>
					{children}
				</Paper>
			)}
			sx={{
				'& .MuiOutlinedInput-root': {
					border: 'none',
					padding: '0px 0px 0px 8px',
					minHeight: '34px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
					border: 'none',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					border: 'none',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
					minHeight: '34px',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
			}}
		/>
	)
}

interface RadioChipListProps {
	options: string[]
	selectedOptions: string[]
	setSelectedOptions: (fields: string[]) => void
	chipStyle?: React.CSSProperties
}

export const RadioChipList = React.memo<RadioChipListProps>(
	({ options, selectedOptions, setSelectedOptions, chipStyle }) => {
		// Regular function to handle option changes
		const handleOptionChange = (option: string) => {
			const updatedFields = selectedOptions.includes(option)
				? selectedOptions.filter((field) => field !== option)
				: [...selectedOptions, option]

			// Only update if the selection actually changed
			if (updatedFields !== selectedOptions) {
				setSelectedOptions(updatedFields)
			}
		}

		return (
			<div style={{ padding: '0.8rem 0' }}>
				<div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
					{options.map((option) => (
						<RadioChip
							key={option}
							option={option}
							value={selectedOptions.includes(option) ? option : ''}
							onChange={() => handleOptionChange(option)}
							disabled={false}
							chipStyle={chipStyle}
						/>
					))}
				</div>
			</div>
		)
	},
)
